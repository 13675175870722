import React from 'react'
import {
  Checkbox,
  CheckboxGroup,
  Col,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Inline,
  Input,
  Row
} from '@te-digi/styleguide'

import { Placeholder } from '../../components/Placeholder'

const RowColResponsiveExample = () => (
  <>
    <Row>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
        <Col
          key={`e_${index}`}
          lg={1}
          md={2}
        >
          <Placeholder block>{index}</Placeholder>
        </Col>
      ))}
    </Row>
    <Divider />
    <Row rowGap="md">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
        <Col
          key={`f_${index}`}
          lg={1}
          md={2}
        >
          <Placeholder block>{index}</Placeholder>
        </Col>
      ))}
    </Row>
  </>
)

const RowColInputResponsiveExample = () => (
  <FormLayout>
    <FormLayoutItem>
      <Row>
        <Col
          key="yksikko"
          md={3}
          xs={12}
        >
          <FormGroup>
            <Input
              label="Yksikkö"
              onChange={() => {}}
              onBlur={() => {}}
              value=""
            />
          </FormGroup>
        </Col>
        <Col
          key="kasittelija"
          md={3}
          xs={12}
        >
          <FormGroup>
            <Input
              label="Käsittelijä"
              onChange={() => {}}
              onBlur={() => {}}
              value=""
            />
          </FormGroup>
        </Col>
        <Col
          key="aihe"
          md={3}
          xs={12}
        >
          <FormGroup>
            <Input
              label="Aihe"
              onChange={() => {}}
              onBlur={() => {}}
              value=""
            />
          </FormGroup>
        </Col>
      </Row>
      <CheckboxGroup
        label="Tila"
        name="checkbox-group-example-1"
        onChange={e => {}}
        value={[1]}
      >
        <Inline
          gapX="xl"
          gapY="sm"
          wrap="wrap"
        >
          <Checkbox value="1">Valmis käsiteltäväksi</Checkbox>
          <Checkbox value="2">Odottaa asiakkaan toimia</Checkbox>
          <Checkbox value="3">Käsittelyssä</Checkbox>
          <Checkbox value="4">Käsitelty</Checkbox>
        </Inline>
      </CheckboxGroup>
    </FormLayoutItem>
  </FormLayout>
)

export { RowColResponsiveExample, RowColInputResponsiveExample }
