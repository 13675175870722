import React, { ComponentProps } from 'react'

import { Col, Container, Paragraph, Row } from '@te-digi/styleguide'

import { RowColResponsiveExample } from 'docs/src/examples/react/RowColResponsiveExample'

import { Link } from '../../components/Link'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Placeholder } from '../../components/Placeholder'
import { Section } from '../../components/Section'

type AlignItems = ComponentProps<typeof Row>['alignItems'][]
type JustifyContent = ComponentProps<typeof Row>['justifyContent'][]

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Container, Row, Col"
    components={[
      { component: Container },
      { component: Row, restElement: 'component' },
      { component: Col, restElement: 'component' }
    ]}
  >
    <Section>
      <Paragraph>
        Sisältöalue voidaan jakaa osiin ja allokoida tilaa halutuin säännöin eri
        alueille. Tyypillisesti grid systemeissä käytetään jakoa 12 osaan, mutta
        myös muita jakoja voidaan käyttää.
      </Paragraph>
      <Playground>
        <Row>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
            <Col
              key={`a_${index}`}
              xs={1}
            >
              <Placeholder block>{index}</Placeholder>
            </Col>
          ))}
        </Row>
      </Playground>

      <Playground format="html">
        <div className="row">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
            <div
              className="col"
              key={`d_${index}`}
            >
              <Placeholder block>{index}</Placeholder>
            </div>
          ))}
        </div>
      </Playground>
      <Playground>
        <Row>
          <Col>
            <Placeholder block />
          </Col>
          <Col>
            <Placeholder block />
          </Col>
          <Col>
            <Placeholder block />
          </Col>
        </Row>
      </Playground>
      <Playground format="html">
        <div className="row">
          <div className="col">
            <Placeholder block />
          </div>
          <div className="col">
            <Placeholder block />
          </div>
          <div className="col">
            <Placeholder block />
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Gap">
      <Paragraph>
        <Code>Gap</Code>-propilla voidaan muuttaa columnien väliä.
      </Paragraph>
      <Playground>
        <Row gap="md">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
            <Col
              key={`b_${index}`}
              xs={1}
            >
              <Placeholder block>{index}</Placeholder>
            </Col>
          ))}
        </Row>
      </Playground>
      <Playground>
        <Row gap="xs">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
            <Col
              key={`c_${index}`}
              xs={1}
            >
              <Placeholder block>{index}</Placeholder>
            </Col>
          ))}
        </Row>
      </Playground>
    </Section>
    <Section title="Sarakkeen leveys">
      <Paragraph>
        Leveyden voi määrittää erikseen selaimen eri leveyksille:{' '}
        <Code>xs</Code> &lt;576px, <Code>sm</Code> ≥576px, <Code>md</Code>{' '}
        ≥768px, <Code>lg</Code> ≥992px, <Code>xl</Code> ≥1200px.
      </Paragraph>
      <Paragraph>
        Alla olevassa esimerkissä tila on jaettu <Code>lg</Code>-koossa
        suhteessa 8+2+2 ja <Code>md</Code>-koossa 6+3+3:
      </Paragraph>
      <Playground>
        <Row>
          <Col
            md={6}
            lg={8}
          >
            <Placeholder block />
          </Col>
          <Col
            md={3}
            lg={2}
          >
            <Placeholder block />
          </Col>
          <Col
            md={3}
            lg={2}
          >
            <Placeholder block />
          </Col>
        </Row>
      </Playground>
      <Playground format="html">
        <div className="row">
          <div className="col-md-6 col-lg-8">
            <Placeholder block />
          </div>
          <div className="col col-md-3 col-lg-2">
            <Placeholder block />
          </div>
          <div className="col col-md-3 col-lg-2">
            <Placeholder block />
          </div>
        </div>
      </Playground>
      <Paragraph>
        Sarakkeen leveyden voi asettaa mukautumaan sisällön mukaan:
      </Paragraph>
      <Playground>
        <Row>
          <Col md="auto">
            <Placeholder block>Sed placerat</Placeholder>
          </Col>
          <Col>
            <Placeholder block />
          </Col>
        </Row>
      </Playground>
      <Playground format="html">
        <div className="row">
          <div className="col-auto">
            <Placeholder block>Sed placerat</Placeholder>
          </div>
          <div className="col">
            <Placeholder block />
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Tasaus">
      {(['start', 'center', 'end'] as AlignItems).map(variant => (
        <Playground key={`1_${variant}`}>
          <Row
            alignItems={variant}
            key={`a_${variant}`}
          >
            <Col md={6}>
              <Placeholder block />
            </Col>
            <Col md={6}>
              <Placeholder
                height={8}
                block
              />
            </Col>
          </Row>
        </Playground>
      ))}
      {(['start', 'center', 'end'] as AlignItems).map(variant => (
        <Playground
          format="html"
          key={`2_${variant}`}
        >
          <div
            className={`row align-items-${variant}`}
            key={`b_${variant}`}
          >
            <div className="col-md-6">
              <Placeholder block />
            </div>
            <div className="col-md-6">
              <Placeholder
                height={8}
                block
              />
            </div>
          </div>
        </Playground>
      ))}
      {(['start', 'center', 'end', 'around', 'between'] as JustifyContent).map(
        variant => (
          <Playground key={`3_${variant}`}>
            <Row
              justifyContent={variant}
              key={`c_${variant}`}
            >
              <Col xs="auto">
                <Placeholder block>Col 1</Placeholder>
              </Col>
              <Col xs="auto">
                <Placeholder block>Col 2</Placeholder>
              </Col>
            </Row>
          </Playground>
        )
      )}
      {(['start', 'center', 'end', 'around', 'between'] as JustifyContent).map(
        variant => (
          <Playground
            format="html"
            key={`4_${variant}`}
          >
            <div
              className={`row justify-content-${variant}`}
              key={`d_${variant}`}
            >
              <div className="col-auto">
                <Placeholder block>Col 1</Placeholder>
              </div>
              <div className="col-auto">
                <Placeholder block>Col 2</Placeholder>
              </div>
            </div>
          </Playground>
        )
      )}
    </Section>
    <Section title="Järjestys">
      <Paragraph>
        Sarakkeiden järjestystä voi vaihtaa selaimen eri leveyksillä.
        Esimerkissä ensimmäinen sarake näkyy toisena isommassa näyttökoossa.
      </Paragraph>
      <Playground>
        <Row>
          <Col
            xs={{ order: 1, size: 6 }}
            md={{ order: 2, size: 6 }}
          >
            <Placeholder block>1.</Placeholder>
          </Col>
          <Col
            xs={{ order: 2, size: 6 }}
            md={{ order: 1, size: 6 }}
          >
            <Placeholder block>2.</Placeholder>
          </Col>
        </Row>
      </Playground>
    </Section>
    <Section title="Sisäkkäiset sarakkeet">
      <Playground>
        <Row>
          <Col xs={6}>
            <Placeholder block>Col 1</Placeholder>
          </Col>
          <Col xs={6}>
            <Placeholder block>
              <div>Col 2</div>
              <Row gap="xs">
                <Col xs={6}>
                  <Placeholder block>Col 1</Placeholder>
                </Col>
                <Col xs={6}>
                  <Placeholder block>Col 2</Placeholder>
                </Col>
              </Row>
            </Placeholder>
          </Col>
        </Row>
      </Playground>
    </Section>
    <Section title="Offset">
      <Paragraph>
        Mahdollistaa tyhjän tilan lisäämisen elementin vasemmalle puolelle grid
        systemin mukaisesti, esimerkissä (1+3)+4+3.
      </Paragraph>
      <Playground>
        <Row>
          <Col md={{ offset: 1, size: 3 }}>
            <Placeholder block />
          </Col>
          <Col md={4}>
            <Placeholder block />
          </Col>
          <Col md={3}>
            <Placeholder block />
          </Col>
        </Row>
      </Playground>
      <Playground format="html">
        <div className="row">
          <div className="col-md-3 offset-md-1">
            <Placeholder block />
          </div>
          <div className="col-md-4">
            <Placeholder block />
          </div>
          <div className="col-md-3">
            <Placeholder block />
          </div>
        </div>
      </Playground>
    </Section>

    <Section title="Responsiivinen asettelu">
      <Paragraph>
        Responsiivisessa asettelussa yhden <Code>Row</Code>n sisällä olevat
        elementit saattavat rivittyä joillakin breakpointeilla useammalle
        visuaaliselle riville. Tällöin niiden väliin saatetaan kaivata tyhjää
        tilaa.
      </Paragraph>
      <Paragraph>
        Lomakkeilla tarvittavan tyhjän tilan saa aikaan ympäröimällä
        input-kentät{' '}
        <Code>
          <Link page="FormGroup" />
        </Code>
        -komponentilla.
      </Paragraph>
      <Paragraph>
        Alla olevaa esimerkkiä kannattaa tarkastella koko ruudun tilassa
        pienentäen ja suurentaen selainikkunaa.
      </Paragraph>
      <Playground example={RowColResponsiveExample} />
    </Section>

    <Section title="Container">
      <Paragraph>
        Lisää sisällölle vasemman ja oikean reunan marginit ja -paddingit.
      </Paragraph>
      <Playground>
        <>
          <Placeholder>Placeholder ilman Containeria</Placeholder>
          <Container>
            <Placeholder>Placeholder Containerin sisällä</Placeholder>
          </Container>
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
