import styled from 'styled-components'
import tokens from '@te-digi/styleguide-tokens'

const Placeholder = styled.div<{
  block?: boolean
  width?: number
  height?: number
}>`
  background-color: ${tokens.color['neutral-2']};
  background-image: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.5px),
      ${tokens.color['neutral-4']} 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.5px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.5px),
      ${tokens.color['neutral-4']} 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.5px),
      rgba(0, 0, 0, 0) 100%
    );
  border: 1px solid ${tokens.color['neutral-4']};
  color: ${tokens.color['neutral-7']};
  display: flex;
  flex-direction: column;
  min-height: ${props => props.height || 4}rem;
  text-align: center;
  justify-content: center;
  width: ${props => (props.block ? '100%' : `${props.width || 6}rem`)};
`

export { Placeholder }
